body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-gutter: stable;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    height: 8px;
    width: 8px;
    background-color: #eeeeee;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #bdbdbd;
  }

  &::-webkit-scrollbar-corner {
    background-color: #bdbdbd;
  }

  &.dark {
    &::-webkit-scrollbar {
      background-color: #424242;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #757575;
    }

    &::-webkit-scrollbar-corner {
      background-color: #757575;
    }
  }
}
